function navBarHandler() {
    var _a;
    // 0 - no fetched, no loaded
    // 1 - fetched, no loded
    // 2 - fetched and loaded
    let submenuFetchStatus = 0;
    let submenuContent = '';
    let lastClicked;
    (_a = document.querySelector('html')) === null || _a === void 0 ? void 0 : _a.addEventListener('click', (e) => {
        var _a, _b;
        const el = e.target;
        const root = document.querySelector('html');
        const navBar = document.querySelector('.navbar');
        if (!navBar) {
            return;
        }
        const menuSearch = navBar.querySelector('.navbar__searchOld');
        const mainMenu = navBar.querySelectorAll('.navbar__menu__ul > li');
        const mobileMenuToggler = navBar.querySelector('.navbar__toggle');
        const liveNowTop = document.querySelector('.liveNowTop');
        const liveNowTopDropdowns = document.querySelectorAll('[data-nav=liveEventsChilds]');
        // Add references to subdomain dropdowns in both header and footer
        const subdomainDropdowns = document.querySelectorAll('.subdomainDropdown');
        function isMobileMode() {
            return window.getComputedStyle(mobileMenuToggler).display !== 'none';
        }
        function hideSearch() {
            if (navBar) {
                navBar.classList.remove('navbar--searchOn');
            }
        }
        function hideUserMenu() {
            if (navBar) {
                navBar.classList.remove('navbar--userMenuOn');
            }
        }
        function hideSubdomainDropdowns() {
            subdomainDropdowns.forEach(dropdown => {
                dropdown.classList.remove('on');
            });
        }
        function removeMenuAllActives() {
            Array.from(mainMenu).forEach((item) => {
                item.classList.remove('on');
                if (isMobileMode()) {
                    item.classList.remove('active');
                }
            });
            hideSearch();
            hideUserMenu();
            hideSubdomainDropdowns();
        }
        function removeLiveNowAllActives() {
            Array.from(liveNowTopDropdowns).forEach((item) => {
                const parent = item.parentNode;
                if (parent)
                    parent.classList.remove('on');
            });
        }
        function removeActiveDesktopMenu() {
            root.classList.remove('activeDesktopMenu', 'activeMenu');
        }
        function hideLiveNow() {
            if (liveNowTop)
                liveNowTop.classList.remove('open');
        }
        function openUserMenu() {
            if (navBar && !navBar.classList.contains('navbar--userMenuOn')) {
                navBar.classList.add('navbar--userMenuOn');
            }
            else {
                hideUserMenu();
            }
        }
        function openSearch() {
            if (navBar) {
                navBar.classList.add('navbar--searchOn');
            }
            const searchInput = document.getElementById('js-siteSearchInput');
            if (searchInput)
                searchInput.focus();
        }
        function hideLRDashboard() {
            const target = document.getElementById('moderateDashboard');
            if (target) {
                target.dispatchEvent(new Event('menu-close'));
                target.style.display = 'none';
            }
        }
        function isNavElement(navElement, navName) {
            let currentElement = navElement;
            while (currentElement && currentElement.nodeName !== 'HTML') {
                if (navName === currentElement.getAttribute('data-nav')) {
                    return true;
                }
                currentElement = currentElement.parentNode;
            }
            return false;
        }
        function isInsideSubdomainDropdown(element) {
            return subdomainDropdowns.length > 0 && Array.from(subdomainDropdowns).some(dropdown => dropdown.contains(element) || element === dropdown);
        }
        if (!isInsideSubdomainDropdown(el)) {
            hideSubdomainDropdowns();
        }
        if (!el.parentElement || !(el.parentNode instanceof HTMLElement && el.parentNode.closest('.navbar')) || el.className === 'navbar') {
            if (el.classList.contains('modalWindowCloseButton')) {
                return;
            }
            removeMenuAllActives();
            removeActiveDesktopMenu();
        }
        if (liveNowTop) {
            if (!((_a = el.parentElement) === null || _a === void 0 ? void 0 : _a.closest('.liveNowTop'))) {
                removeLiveNowAllActives();
                hideLiveNow();
            }
        }
        function insertSubmenu() {
            const lastClickedEl = lastClicked;
            if (!lastClickedEl || lastClickedEl.submenuFilled) {
                return;
            }
            lastClickedEl.submenuFilled = true;
            if (typeof submenuContent === 'string') {
                return;
            }
            const data = submenuContent;
            const menuIdElement = lastClickedEl.querySelector('[data-pageid]');
            if (!(menuIdElement === null || menuIdElement === void 0 ? void 0 : menuIdElement.dataset.pageid)) {
                return;
            }
            const menuID = menuIdElement.dataset.pageid;
            const column1 = el.querySelector('.dropdownMenu__content1');
            const column2 = lastClickedEl.querySelector('.dropdownMenu__content2');
            if (!menuID || !(data.menu && menuID in data.menu)) {
                return;
            }
            const { 1: column1Data, 2: column2Data } = data.menu[menuID];
            if (column1 && column1Data) {
                column1.innerHTML = column1Data;
            }
            else if (column1 && column1.parentNode instanceof HTMLElement) {
                column1.parentNode.classList.add('noFirstColumn');
            }
            if (column2 && column2Data) {
                column2.innerHTML = column2Data;
            }
            else if (column2 && column2.parentNode instanceof HTMLElement) {
                column2.parentNode.classList.add('noSecondColumn');
            }
        }
        function subMenuContentInit(subEl) {
            lastClicked = subEl;
            if (submenuFetchStatus === 2) {
                insertSubmenu();
            }
            else if (submenuFetchStatus === 0) {
                const preloader = el.querySelector('.preloader');
                if (preloader)
                    preloader.style.display = 'block';
                submenuFetchStatus = 1;
                fetch('/navigation.json')
                    .then((response) => response.json())
                    .then((data) => {
                    submenuContent = data;
                    submenuFetchStatus = 2;
                    insertSubmenu();
                    if (preloader)
                        preloader.style.display = 'none';
                })
                    .catch((error) => {
                    throw new Error(`An error occurred while fetching the navigation endpoint: ${error.message}`);
                });
            }
        }
        switch (true) {
            case isNavElement(el, 'subdomains'): {
                // Toggle subdomain dropdown in header
                const parentDropdown = el.closest('.subdomainDropdown');
                if (parentDropdown) {
                    // Close other subdomain dropdowns first
                    subdomainDropdowns.forEach(dropdown => {
                        if (dropdown !== parentDropdown) {
                            dropdown.classList.remove('on');
                        }
                    });
                    parentDropdown.classList.toggle('on');
                    // Close user menu when subdomain dropdown is toggled
                    hideUserMenu();
                }
                break;
            }
            case isNavElement(el, 'navbarSearch'):
                // Open on search button click
                if (!menuSearch.classList.contains('on')) {
                    openSearch();
                }
                // Close search on close button click
                if (el.classList.contains('closeSearch')) {
                    hideSearch();
                }
                break;
            case isNavElement(el, 'livestream'): {
                e.stopPropagation();
                e.preventDefault();
                const targetElement = el;
                if (targetElement.dataset.livestreamurl) {
                    window.location.href = targetElement.dataset.livestreamurl;
                }
                break;
            }
            case isNavElement(el, 'liveEventsChilds'):
                Array.from(liveNowTopDropdowns).forEach((item) => {
                    const parent = item.parentNode;
                    if (el === item) {
                        if (parent)
                            parent.classList.toggle('on');
                    }
                    else {
                        if (parent)
                            parent.classList.remove('on');
                    }
                });
                break;
            case isNavElement(el, 'mobileMenuToggler'):
                if (liveNowTop) {
                    hideLiveNow();
                }
                if (!root.classList.contains('activeMenu')) {
                    root.classList.add('activeMenu');
                }
                else {
                    root.classList.remove('activeMenu');
                }
                break;
            case isNavElement(el, 'liveEventsToggler'):
                if (liveNowTop)
                    liveNowTop.classList.toggle('open');
                if (mobileMenuToggler instanceof HTMLInputElement) {
                    mobileMenuToggler.checked = false;
                }
                root.classList.remove('activeMenu');
                break;
            case isNavElement(el, 'avatar'):
                // Close subdomain dropdowns when avatar is clicked
                hideSubdomainDropdowns();
                openUserMenu();
                break;
            case isNavElement(el, 'hideUserMenu'):
                hideUserMenu();
                break;
            case isNavElement(el, 'navbarMenu'):
                if (isMobileMode()) {
                    // ******* PHONE *********
                    // Close active dropdown if you click on it twice
                    if (el.classList.contains('on')) {
                        removeMenuAllActives();
                        return;
                    }
                    // If clicked parent is main menu, add "activeSubmenuMenu"
                    if ((_b = el.parentElement) === null || _b === void 0 ? void 0 : _b.classList.contains('navbar__menu__ul')) {
                        removeMenuAllActives();
                    }
                }
                else {
                    if (el.classList.contains('navbar__menu__firstlevel')) {
                        subMenuContentInit(el);
                        root.classList.add('activeDesktopMenu');
                    }
                    // ******* DESKTOP *********
                    // Do not close dropdown if you click on submenu element. Bad expierence
                    if ((el.parentNode instanceof HTMLElement && (el.parentNode.closest('li.dropdown.on') || el.parentNode.closest('div.dropdown.on')))) {
                        return;
                    }
                    // Close active dropdown if you click on it twice
                    if (el.classList.contains('on')) {
                        removeMenuAllActives();
                        removeActiveDesktopMenu();
                        return;
                    }
                    removeMenuAllActives();
                }
                el.classList.add('on');
                hideLRDashboard();
                break;
            default:
                break;
        }
        document.addEventListener('closeAllMenus', () => {
            removeMenuAllActives();
            removeLiveNowAllActives();
            if (liveNowTop) {
                hideLiveNow();
            }
            removeActiveDesktopMenu();
        });
    });
}
export default navBarHandler;
